<template>
    <el-dialog :title="!dataForm.recNo ? '新增监理日志' : '修改监理日志'" :close-on-click-modal="false" :visible.sync="visible"
        width="850px">
        <el-form ref="dataForm" :model="dataForm" inline label-width="140px" label-position="left">
            <el-row>
                <el-form-item label="Shift No">
                    <el-input class="selItemInput" type="input" v-model="dataForm.logNo"></el-input>
                </el-form-item>
                <el-form-item label="工程地点">
                    <el-input class="selItemInput" type="input" v-model="dataForm.projectPlace"
                        placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="日志时间">
                    <el-date-picker class="selItemInput" value-format="yyyy-MM-dd" v-model="dataForm.logDate" type="date"
                        style="width:300px" @change="resetInfo">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="天气">
                    <el-input class="selItemInput" type="input" v-model="dataForm.weather" placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="监理工程师">
                    <el-input class="selItemInput" type="input" v-model="dataForm.engineer" placeholder="请填写"></el-input>
                </el-form-item>
                <el-form-item label="监理组长">
                    <el-input class="selItemInput" type="input" v-model="dataForm.leader" placeholder="请填写"></el-input>
                </el-form-item>
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>本日（班）工作概要</span>
                        <el-button v-preventReClick style="float: right; padding: 3px 0" type="text"
                            @click="getJobSummaryInfo">重新获取</el-button>
                    </div>
                    <div style="white-space: pre-wrap;">
                        {{ changeLine(dataForm.jobSummary) }}
                    </div>
                </el-card>
                <el-card class="box-card" style="margin-top: 5px">
                    <div slot="header" class="clearfix">
                        <span>本日（班）签发文件清单</span>
                        <el-button v-preventReClick style="float: right; padding: 3px 0" type="text"
                            @click="getFileListInfo">重新获取</el-button>
                    </div>
                    <div style="white-space: pre-wrap;">
                        {{ changeLine(dataForm.fileList) }}
                    </div>
                </el-card>
                <el-card class="box-card" style="margin-top: 5px">
                    <div slot="header" class="clearfix">
                        <span>本日（班）备忘</span>
                        <el-button v-preventReClick style="float: right; padding: 3px 0" type="text" @click="getMentionInfo">重新获取</el-button>
                    </div>
                    <div style="white-space: pre-wrap;">
                        {{ changeLine(dataForm.mention) }}
                    </div>
                </el-card>
            </el-row>
            <el-row class="formBtnGroup">
                <el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="resetInfo()">重置日志数据</el-button>
                <el-button v-preventReClick class="primaryPlainBtn" type="primary" plain @click="visible = false">取消</el-button>
                <el-button v-preventReClick class="primaryPlainBtn" type="primary" @click="dataFormSubmit()">确定</el-button>
            </el-row>
        </el-form>
    </el-dialog>
</template>

<script>
import $common from "@/utils/common.js"
export default {
    name: "workloginfo-add-or-update",
    data() {
        return {
            visible: true,
            dataForm: {
                workNo: $common.getItem("workNo"),
                recNo: '',
                logNo: '',
                projectPlace: "",
                logDate: '',
                weather: '',
                engineer: '',
                leader: '',
                jobSummary: '',
                fileList: '',
                mention: ''
            },
        }
    },
    components: {

    },
    methods: {
        getCurrentTime() {
            //获取当前时间并打印
            var _this = this;
            let yy = new Date().getFullYear();
            let mm = (new Date().getMonth() + 1) < 10 ? '0' + (new Date().getMonth() + 1) : (new Date().getMonth() + 1);
            let dd = (new Date().getDate()) < 10 ? '0' + (new Date().getDate()) : (new Date().getDate());
            // let dd = new Date().getDate();
            let hh = new Date().getHours();
            let mf = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
            let ss = new Date().getSeconds() < 10 ? '0' + new Date().getSeconds() : new Date().getSeconds();
            return yy + '-' + mm + '-' + dd;
        },
        init(id) {
            this.dataForm = {
                workNo: $common.getItem("workNo"),
                recNo: '',
                logNo: '',
                projectPlace: "",
                logDate: '',
                weather: '',
                engineer: '',
                leader: '',
                jobSummary: '',
                fileList: '',
                mention: ''
            }
            this.dataForm.recNo = id ? id : 0;
            this.dataForm.logDate = this.getCurrentTime();
            if (id) {
                this.getDetail();
            } else {
                this.resetInfo()
            }
            this.$nextTick(() => {
                this.visible = true;
            })
        },
        getDetail() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/info/" + this.dataForm.recNo,
                method: "get",
                params: {},
            }).then(({
                data
            }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm = data.body;
                }
            });
        },
        getPresetInfo() {
            this.$http({
                url: this.$store.state.httpUrl + "/business/worklogpreset/presetInfo/" + $common.getItem("workNo"),
                method: "get"
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.logNo = data.body.logNo;
                    this.dataForm.projectPlace = data.body.projectPlace;
                    this.dataForm.leader = data.body.leader;
                    this.dataForm.logNo = data.body.logNo;
                    this.dataForm.engineer = data.body.engineer;
                }
            });
        },
        getJobSummaryInfo() {
            if (!this.dataForm.logDate) {
                return
            }
            let data = {
                workNo: $common.getItem("workNo"),
                logDate: this.dataForm.logDate
            }
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/jobSummaryInfo",
                method: "get",
                params: this.$http.adornParams(data),
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.jobSummary = data.body;
                }
            });
        },
        getFileListInfo() {
            if (!this.dataForm.logDate) {
                return
            }
            let data = {
                workNo: $common.getItem("workNo"),
                logDate: this.dataForm.logDate
            }
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/fileListInfo",
                method: "get",
                params: this.$http.adornParams(data),
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.fileList = data.body;
                }
            });
        },
        getMentionInfo() {
            if (!this.dataForm.logDate) {
                return
            }
            let data = {
                workNo: $common.getItem("workNo"),
                logDate: this.dataForm.logDate
            }
            this.$http({
                url: this.$store.state.httpUrl + "/business/workloginfo/mentionInfo",
                method: "get",
                params: this.$http.adornParams(data),
            }).then(({ data }) => {
                if (data && data.resultCode === 200) {
                    this.dataForm.mention = data.body;
                }
            });
        },
        changeLine(str) {
            return str.replaceAll('\r','\n')
        },
        resetInfo() {
            this.getPresetInfo()
            this.getJobSummaryInfo()
            this.getFileListInfo()
            this.getMentionInfo()
        },
        dataFormSubmit() {
            this.$refs["dataForm"].validate(valid => {
                if (valid) {
                    let _url = "/business/workloginfo/save";
                    if (this.dataForm.recNo && this.dataForm.recNo !== 0) {
                        _url = "/business/workloginfo/update";
                    }
                    this.$http({
                        url: this.$store.state.httpUrl + _url,
                        method: "post",
                        data: this.$http.adornData(this.dataForm)
                    }).then(({
                        data
                    }) => {
                        if (data && data.resultCode === 200) {
                            this.$message({
                                message: "操作成功",
                                type: "success",
                                duration: 1500,
                                onClose: () => {
                                    this.visible = false;
                                    this.$parent.getDataList();
                                }
                            });
                        } else {
                            this.$message.error(data.msg);
                        }
                    });
                }
            });
        },
    }
}
</script>

<style lang="scss" scoped="scoped">
.selItemInput.descAreaLg {
    width: 718px !important;
}

.width391 {
    width: 391px !important;
}

.addImgBox {
    width: 136px;
    height: 136px;
    display: inline-block;
    vertical-align: middle;
    margin-right: 10px;
}


.fondstyle {
    color: #3f649f;
    font-size: 15px;
}
</style>
